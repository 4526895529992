import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {MatDialog} from '@angular/material/dialog';


import {Occurrence} from '../../../shared/model/occurrence';
import {ImageDialogComponent} from '../image-dialog/image-dialog.component';

const EXIF = require('exif-js');

@Component({
  selector: 'app-occurrence-detail-botton-sheet',
  templateUrl: './occurrence-botton-sheet.component.html',
  styleUrls: ['./occurrence-botton-sheet.component.scss']
})
export class OccurrenceBottonSheetComponent implements OnInit {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: Occurrence,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
  }

  openImage() {
    this.dialog.open(ImageDialogComponent, {
      data: this.data.url_image
    });
  }
}
