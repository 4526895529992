<app-main-nav *ngIf="authService.loggedUser; else notLogged" class="ifMobile">
  <div class="content">
    <router-outlet *ngIf="!loadingModule"></router-outlet>
    <ng-container *ngIf="loadingModule"><app-logo-loading></app-logo-loading></ng-container>
  </div>
</app-main-nav>

<ng-template #notLogged class="ifMobile">
  <router-outlet *ngIf="!loadingModule"></router-outlet>
  <ng-container *ngIf="loadingModule"><app-logo-loading></app-logo-loading></ng-container>
</ng-template>

<div class="container h-100 justMobile" >
  <div class="row h-100">
    <div class="col-12 h-100 d-flex justify-content-center align-items-center">
      <h3>painel indisponivel em aparelhos moveis, por favor acesse de um computador</h3>
    </div>
  </div>
</div>
