<button mat-icon-button color="{{ color }}" *ngIf="!loading; else protocolLoading"
        [disabled]="disabled">
  <mat-icon svgIcon="content-save"></mat-icon>
</button>

<ng-template #protocolLoading>
  <div class="spinner-align">
    <button mat-icon-button>
      <mat-spinner diameter="25"></mat-spinner>
    </button>
  </div>
</ng-template>
