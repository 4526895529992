<div class="container mh-50">
  <div class="row">
    <div class="col-auto ml-auto col-btn-edit">
      <a target="_blank" color="primary" [routerLink]="['/occurrences/', data.id ]">
        Editar
        <mat-icon svgIcon="open-in-new"></mat-icon>
      </a>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-5" *ngIf="data.url_image">
      <div class="row">
        <div class="col col-img text-center">
          <img class="rounded" [src]="data.url_image" alt="Foto da ocorrencia" (click)="openImage()">
          <!--          <iframe [src]="data.url_image | safe: 'resourceUrl'"></iframe>-->
        </div>
      </div>
    </div>

    <div [ngClass]="data.url_image ? 'col-sm-12 col-md-7' : 'col'" style="min-height: 200px">
      <div class="row ">
        <div class="col col-scroll">

          <div class="row">
            <div class="col">
              <strong>Tipo:</strong> {{data.type_name}}
            </div>
            <!--            <div class="col-auto mr-auto">-->
            <!--              <a target="_blank" color="primary" [routerLink]="['/occurrences/', data.id ]">-->
            <!--                Editar-->
            <!--                <mat-icon svgIcon="open-in-new"></mat-icon>-->
            <!--              </a>-->
            <!--            </div>-->
          </div>

          <div class="row">
            <div class="col"><strong>Data de abertura:</strong>  {{data.date_started | date:"dd/MM/yyy"}}
            </div>
          </div>

          <div class="row">
            <div class="col"><strong>Secretaria:</strong> {{data.office_name}}</div>
          </div>

          <div class="row">
            <div class="col"><strong>Endereço:</strong> {{ data.address }}</div>
          </div>

          <div class="row">
            <div class="col"><strong>Descrição:</strong> {{ data.description }}</div>
          </div>

          <div class="row">
            <div class="col"><strong>Status:</strong> {{ data.status }}</div>
          </div>

          <div class="row">
            <div class="col"><strong>Prioridade:</strong> {{ data.priority }}</div>
          </div>

          <div class="row">
            <div class="col">
              <strong>Previsão:</strong> {{ (data.completion_date | date:"dd/MM/yyy") || 'Não informado' }}
            </div>
          </div>

          <ng-container *ngIf="data.historic.length > 0">
            <div class="row" id="history-header">
              <div class="col text-center">
                <strong>Histórico</strong>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <mat-divider></mat-divider>
              </div>
            </div>
          </ng-container>

          <ng-container *ngFor="let item of data.historic; let position = index">
            <app-history-list [historic]="data.historic"></app-history-list>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

