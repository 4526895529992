import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';


// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

import {AmplifyService} from 'aws-amplify-angular';
import {graphqlOperation} from 'aws-amplify';

import {fromPromise} from 'rxjs/internal-compatibility';

import {AppCommon} from '../../../shared/model/common';
import {Occurrence} from '../../../shared/model/occurrence';

import {AuthService} from '../../../core/auth/auth.service';

import {CacheService} from '../../cache/cache.service';

import {ImageDialogComponent} from '../image-dialog/image-dialog.component';

import {getOccurrence} from '../../../../graphql/queries';
import {addNoteToOccurrence, updateOccurrence, updateOccurrenceProtocol} from '../../../../graphql/mutations';

@Component({
  selector: 'app-form-occurrence',
  templateUrl: './form-occurrence.component.html',
  styleUrls: ['./form-occurrence.component.scss'],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'AAAA/mm/DD',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'YYYY/MM/DD',
        }
      }
    },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class FormOccurrenceComponent implements OnInit {
  static occurrenceEmitter = new EventEmitter();

  spinnerLoading = false;
  isHidden = true;
  loading = true;
  loadingEditForm = false;
  loadingProtocolform = false;
  canEdit = false;
  protocolForm: FormGroup;
  noteForm: FormGroup;
  editForm: FormGroup;
  occurrence = new Occurrence();
  statusOptions: Array<{ name: string }>;
  minDate = new Date(2018, 1, 1);
  maxDate = new Date(2120, 12, 31);
  date = new FormControl(moment(['yyyy/MM/dd']));
  groupItems = false;
  // tslint:disable-next-line:variable-name
  url_image: string;
  // tslint:disable-next-line:variable-name
  image_link;

  constructor(
    private amplifyService: AmplifyService,
    private activateRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private dialog: MatDialog,
    private cacheService: CacheService,
    private appCommon: AppCommon,
  ) {
    // busca os status de ocorrencias do usuario e preencher o select
    this.authService.occurrencesStatus.subscribe(status => {
        this.statusOptions = status;
      }
    );

    // Pega id da URL e carrega usuario
    this.activateRoute.params.subscribe(params => {
      const id = params.id;
      this.occurrence.id = id;

      if (id !== undefined && id !== null) {
        fromPromise(this.amplifyService.api().graphql(graphqlOperation(getOccurrence, {id})))
          .subscribe(
            resp => {
              const result: any = resp; // so para corrigir bug 'Property 'data' does not exist on type '{}''
              this.occurrence = result.data.getOccurrence;
              this.occurrence.priority = this.appCommon.priorityEnum(this.occurrence.priority);
              FormOccurrenceComponent.occurrenceEmitter.emit(this.occurrence);

              this.resetForm();
              this.noteForm.controls.id.setValue(this.occurrence.id);

              this.loading = false;

              // habilita o noteForm para ocorrências em aberto
              if (!this.occurrence.closed) {
                this.noteForm.enable();
              }
            },
            error => console.log(error)
          );

      } else {
        this.loading = false;
      }
    });


    // busca as telas que o usuario tem permissao
    this.authService.permissions.subscribe(permissions => {
        const permission = permissions.find(item => item.name === 'list-occurrences');
        if (permission) {
          this.canEdit = permission.action === 'rw';
        }
      }
    );
  }

  ngOnInit() {
    this.initForm();

  }

  /**
   * Desativa formulario e volta os valores iniciais
   */
  resetForm() {
    this.editForm.reset(Object.assign({}, this.occurrence));
    this.editForm.disable();
    this.image_link = null;
    if (this.occurrence.closed || !this.canEdit) {
      this.noteForm.disable();
    }
  }

  getIcon(icon: string) {
    return 'assets/img/occurrence-type/marker-' + icon + '.svg';
  }

  /**
   * Cria o formulario/controle
   */
  initForm() {
    this.editForm = this.formBuilder.group({
      id: new FormControl(),
      status: new FormControl(this.occurrence.status, [Validators.required]),
      priority: new FormControl(this.appCommon.priorityEnum(
        item => this.occurrence.priority),
        [Validators.required]
      ),
      completion_date: new FormControl(this.occurrence.completion_date),
      note: new FormControl(null),
      closed: new FormControl(this.occurrence.closed),
      image: new FormControl(null),
      external_protocol: new FormControl(this.occurrence.last_protocol),
    });

    this.noteForm = this.formBuilder.group({
      id: new FormControl(),
      public: false,
      // public: new FormControl(false, [Validators.required]),
      note: new FormControl(null, [Validators.required]),
    });

    this.protocolForm = this.formBuilder.group({
      protocol: new FormControl(this.occurrence.protocol, [Validators.required]),
      id: new FormControl(this.occurrence.id)
    });

    // desabilita os forms antes do loading
    if (this.loading) {
      this.editForm.disable();
      this.noteForm.disable();
    }
  }

  onSubmitForm() {
    this.loadingEditForm = true;
    this.editForm.disable();
    const occurrence: Occurrence = this.editForm.getRawValue();
    occurrence.image = this.url_image;

    fromPromise(this.amplifyService.api().graphql(graphqlOperation(
      updateOccurrence, occurrence)))
      .subscribe(
        resp => {
          const result: any = resp; // so para corrigir bug 'Property 'data' does not exist on type '{}''
          this.occurrence = result.data.updateOccurrence;
          this.occurrence.priority = this.appCommon.priorityEnum(this.occurrence.priority);

          this.cacheService.update(this.occurrence);
          this.resetForm();
          this.url_image = null;

          this.loadingEditForm = false;

          this.snackBar.open('Ocorrência salva com sucesso.', 'OK', {
            duration: 2000
          });
        },
        error => {
          console.log(error);
          this.loadingEditForm = false;
          this.snackBar.open('Erro ao salvar, se o problema persistir contate o suporte.', 'OK');
        }
      );
  }

  /**
   * Finaliza formulario para enviar uma resposta ou uma observacao
   */
  onSubmitFormNote() {
    // this.loading = true;
    this.spinnerLoading = true;
    this.noteForm.disable();

    const noteObject: any = this.noteForm.getRawValue();

    fromPromise(this.amplifyService.api().graphql(graphqlOperation(
      addNoteToOccurrence, noteObject)))
      .subscribe(
        resp => {
          const result: any = resp; // so para corrigir bug 'Property 'data' does not exist on type '{}''
          this.occurrence = result.data.addNoteToOccurrence;
          this.occurrence.priority = this.appCommon.priorityEnum(this.occurrence.priority);

          this.noteForm.reset(Object.assign({}, {id: this.occurrence.id, public: false}));
          this.noteForm.enable();

          if (this.occurrence.closed) {
            this.noteForm.disable();
          }
          this.spinnerLoading = false;
        },
        error => {
          console.log(error);
          this.spinnerLoading = false;
          this.noteForm.reset();
          this.noteForm.controls.id.setValue(this.occurrence.id);
          this.snackBar.open('Erro ao salvar, se o problema persistir contate o suporte.', 'OK');
        }
      );
  }

  onSubmitFormProtocol() {
    this.loadingProtocolform = true;

    const protocolObject = this.protocolForm.getRawValue();
    fromPromise(this.amplifyService.api().graphql(graphqlOperation(
      updateOccurrenceProtocol, protocolObject)))
      .subscribe(resp => {
          this.loadingProtocolform = false;

          const result: any = resp; // so para corrigir bug 'Property 'data' does not exist on type '{}''
          this.occurrence = result.data.updateOccurrenceProtocol;
          this.occurrence.priority = this.appCommon.priorityEnum(this.occurrence.priority);

          this.cacheService.update(this.occurrence);
          this.resetForm();
        },
        error => {
          console.log(error);
          this.loadingProtocolform = false;
          this.snackBar.open('Erro ao salvar, se o problema persistir contate o suporte.', 'OK');
        });
  }

  /**
   * converte o LAT e LONG para string
   * @param value lat e long do MAPS
   */
  convertString(value): number {
    return parseFloat(value);
  }

  openImage() {
    this.dialog.open(ImageDialogComponent, {
      data: this.occurrence.url_image
    });
  }

  // converte imagem para base64
  handleUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.url_image = reader.result.toString();
      this.url_image = this.url_image.replace(/^data:image\/[a-z]+;base64,/, '');
    };
    this.image_link = file.name;
  }

}
