<ng-container *ngFor="let item of notes; let position = index">
  <div class="row margin-top">
    <div class="col mat-h4">
      <strong>{{item.operator_name}}</strong>
    </div>

    <div class="col mat-h4 text-right">
      <strong>{{item.date | date:"dd/MM/yyy HH:mm" }}</strong>
    </div>
  </div>

  <div class="row margin-bottom" *ngIf="item.note.trim()">
    <div class="col mat-h4 text-break-line">
      <strong>Observação </strong>{{ item.note }}
    </div>
  </div>

  <div class="row" *ngIf="(position + 1) < notes.length">
    <div class="col">
      <mat-divider></mat-divider>
    </div>
  </div>

</ng-container>
