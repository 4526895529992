<!--
VIEW IN FULL SCREEN MODE
FULL SCREEN MODE: http://salehriaz.com/404Page/404.html

DRIBBBLE: https://dribbble.com/shots/4330167-404-Page-Lost-In-Space
-->

<body class="bg-purple">

<div class="stars">
  <div class="custom-navbar">
    <a routerLink="">
      <div class="brand-logo">
        <img src="../../../../assets/img/logo-evo.svg" width="120px">
      </div>
    </a>
    <!--<div class="navbar-links">-->
    <!--<ul>-->
    <!--<li><a href="http://salehriaz.com/404Page/404.html" target="_blank">Home</a></li>-->
    <!--<li><a href="http://salehriaz.com/404Page/404.html" target="_blank">About</a></li>-->
    <!--<li><a href="http://salehriaz.com/404Page/404.html" target="_blank">Features</a></li>-->
    <!--<li><a href="http://salehriaz.com/404Page/404.html" class="btn-request" target="_blank">Request A Demo</a></li>-->
    <!--</ul>-->
    <!--</div>-->
  </div>
  <div class="central-body">
    <img class="image-404" src="../../../../assets/img/not-found-page/404.svg" width="300px">
    <!--<a href="http://salehriaz.com/404Page/404.html" class="btn-go-home" target="_blank">GO BACK HOME</a>-->
  </div>
  <div class="objects">
    <img class="object_rocket" src="../../../../assets/img/not-found-page/rocket.svg" width="40px">
    <div class="earth-moon">
      <img class="object_earth" src="../../../../assets/img/not-found-page/earth.svg" width="100px">
      <img class="object_moon" src="../../../../assets/img/not-found-page/moon.svg" width="80px">
    </div>
    <div class="box_astronaut">
      <img class="object_astronaut" src="../../../../assets/img/not-found-page/astronaut.svg" width="140px">
    </div>
  </div>
  <div class="glowing_stars">
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>

  </div>

</div>

</body>
