import {Note} from './note';
import {History} from './history';

export class Generic {
  __typename: string;
  id: string;
  protocol: string;
  city: string;
  type_id: string;
  type_name: string;
  date_started: string;
  office_id: string;
  office_name: string;

  address: string;
  description: string;
  responsible: string;
  completion_date: string;
  historic: Array<History> = [];
  notes: Array<Note> = [];
  end_date: string;
  icon: string;
  status: string;
  priority: string;
  priority_number: number;
  closed: boolean;
  date_closed: string;

  created_by: string;
  user_name: string;
  user_email: string;
  user_phone: string;
}
