<div class="container" style="padding-top: 32px">
  <div class="row">
    <div class="col">
      <span class="mat-5 mat-body-strong">{{ title }}</span>
    </div>
  </div>
  <div class="row" style="padding:0 50px 0 24px">
    <ng-content class="mat-subheading-1 font-color-gray"></ng-content>
  </div>
</div>
