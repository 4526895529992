<ng-container *ngFor="let item of historic; let position = index">
  <mat-card class="shadow-none" style="border-radius: 0; padding: 8px;">

    <div class="image-box">
      <img mat-card-image src="{{item.url_image}}" alt="Foto da ocorrencia"
           class="image" *ngIf="item.url_image" (click)="openImage(position)"
           style="height: 170px;">

      <div class="row">
        <div class="col mat-h5 no-margin no-padding-right">
          <strong>{{item.operator_name}}</strong>
        </div>

        <div class="col mat-h5 text-right no-margin no-padding-left">
          <strong>{{item.date | date:"dd/MM/yyy HH:mm" }}</strong>
        </div>
      </div>

      <div class="row">
        <div class="col-6 mat-h5 no-margin no-padding-right">
          <strong>Status: </strong> {{ item.status }}
        </div>
        <div class="col mat-h5 text-right no-margin no-padding-left">
          <strong>Prioridade: </strong> {{ item.priority }}
        </div>
      </div>

      <div class="row">
        <div class="col-6 mat-h5 no-margin no-padding-right" *ngIf="item.completion_date && !item.closed">
          <strong>Previsão: </strong>{{item.completion_date | date:"dd/MM/yyy"}}
        </div>

        <div class="col mat-h5 no-margin no-padding-righ" *ngIf="item.closed">
          <strong>Finalizado</strong>
        </div>
      </div>


      <div class="row">
        <div class="col mat-h5 no-margin " *ngIf="item.note">
          <strong>Observacao pública: </strong>{{item.note}}
        </div>
      </div>

    </div>
  </mat-card>
  <mat-divider></mat-divider>

</ng-container>
