<!--<div class="container">-->

<div class="row" *ngIf="isFooter;">
  <div class="col">
    <mat-divider></mat-divider>
  </div>
</div>

<!--<div class="action-buttons">-->
<div id="buttons" class="row justify-content-end action-buttons">
  <ng-content></ng-content>
</div>
<!--</div>-->
