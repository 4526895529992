// import 'hammerjs';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import Amplify, {Auth} from 'aws-amplify';
import awsmobile from './aws-exports';
import {environment} from './app/pages/environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


if (environment.production) {

  environment.version = 'D - 2.2'

  // DESENVOLVIMENTO
  Amplify.configure(awsmobile);

  Amplify.configure({
    aws_appsync_graphqlEndpoint: 'https://sidjjva7u5axzlvf3rexqx2eou.appsync-api.us-east-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'da2-5yolvdaaabbcrgtdavrxmxh4ke',
  });

  Amplify.configure({
    Auth: {
      identityPoolId: 'us-east-2:3809aa79-e7cf-4cf7-a766-614553927f8b',
      region: 'us-east-2',
      userPoolId: 'us-east-2_6pf8fnuIz',
      userPoolWebClientId: '5se63vll5o543en1193fp3dbsf'
    },
    API: {
      graphql_headers: async () => {
        return {Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()};
      }
    }
  });

} else {

  environment.version = 'D - 2.2'

  // DESENVOLVIMENTO
  Amplify.configure(awsmobile);

  Amplify.configure({
    aws_appsync_graphqlEndpoint: 'https://sidjjva7u5axzlvf3rexqx2eou.appsync-api.us-east-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'da2-5yolvdaaabbcrgtdavrxmxh4ke',
  });

  Amplify.configure({
    Auth: {
      identityPoolId: 'us-east-2:3809aa79-e7cf-4cf7-a766-614553927f8b',
      region: 'us-east-2',
      userPoolId: 'us-east-2_6pf8fnuIz',
      userPoolWebClientId: '5se63vll5o543en1193fp3dbsf'
    },
    API: {
      graphql_headers: async () => {
        return {Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()};
      }
    }
  });

}
