import {Component, Input, OnInit} from '@angular/core';
import {AmplifyService} from 'aws-amplify-angular';
import {NavigationStart, Router} from '@angular/router';
import {fromPromise} from 'rxjs/internal-compatibility';
import {FormOccurrenceComponent} from '../../../pages/occurrences/form-occurrence/form-occurrence.component';
import {FormOccurrencesGroupedComponent} from '../../../pages/occurrences-grouped/form-occurrences-grouped/form-occurrences-grouped.component';
import {FormOmbudsmanComponent} from '../../../pages/ombudsman/form-omdubsman/form-ombudsman.component';

@Component({
  selector: 'app--main-nav-mobile',
  templateUrl: './main-nav-mobile.component.html',
  styleUrls: ['./main-nav-mobile.component.scss']
})
export class MainNavMobileComponent implements OnInit {
  opened: boolean;
  ticket;
  occurrence;
  occurrenceGroup;

  @Input()
  pagesPermission: Array<string> = [];

  constructor(
    private amplifyService: AmplifyService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    FormOccurrenceComponent.occurrenceEmitter.subscribe(
      occurrence => this.occurrence = occurrence.id,
    )
    FormOccurrencesGroupedComponent.occurrenceGroupedEmitter.subscribe(
      occurrence => this.occurrenceGroup = occurrence.geo_group_id,
    )

    FormOmbudsmanComponent.ticketEmitter.subscribe(
      ticket => this.ticket = ticket.id,
    )
  }

  /**
   * Verifica se usuario tem permissao para acessar pagina
   * @param pages Array<string> id das paginas que serao verificadas
   */
  hasPermission(pages: Array<string>): boolean {
    for (const page of pages) {
      if (this.pagesPermission.includes(page)) {
        return true;
      }
    }
    return false;
  }

  logoutBtn() {
    fromPromise(this.amplifyService.auth().signOut())
      .subscribe(() => this.router.navigate(['/login']));
  }

}
