<div class="title">
  <h5 style="color: white">Atualizações do sistema</h5>
</div>
<mat-accordion>

  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Versão 2.3 - 25/01/2021
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list role="list">
      <mat-list-item role="listitem">•Adicionado funcionalidade de download de PDF com informações da medida protetiva selecionada.</mat-list-item>
      <mat-list-item role="listitem">•Adicionado funcionalidade street view (visão 3D) no mapa da tela de monitoramento.</mat-list-item>
      <mat-list-item role="listitem">•Adicionado responsividade no modal de informações da ocorrência.</mat-list-item>
    </mat-list>
  </mat-expansion-panel>

  <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Versão 2.2 - 04/12/2020
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list role="list">
      </mat-list>
    </mat-expansion-panel>

  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Versão 2.1 - 05/11/2020
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list role="list">
      <mat-list-item role="listitem">•Atualização da tela de monitoramento</mat-list-item>
      <mat-list-item role="listitem">•Cadastro de usuários mobile (Professor / funcionário )</mat-list-item>
      <mat-list-item role="listitem">•Cadastro de medida protetiva</mat-list-item>
      <mat-list-item role="listitem">•Cadastro do suspeito</mat-list-item>
    </mat-list>
  </mat-expansion-panel>
</mat-accordion>


