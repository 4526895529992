import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LogoLoadingComponent} from './logo-loading.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {LayoutModule} from '@angular/cdk/layout';

@NgModule({
  declarations: [
    LogoLoadingComponent
  ],
  imports: [
    CommonModule,
    LayoutModule,
    MatProgressSpinnerModule,

  ],
  exports: [
    LogoLoadingComponent
  ]
})
export class LogoLoadingModule {
}
