<mat-progress-bar *ngIf="loading" mode="indeterminate" color="accent"></mat-progress-bar>

<app-header-page backButtonPath="/occurrences-grouped/list-grouped">
    <span *ngIf="!loading; else titleLoading">
    {{ officeName || "Ocorrência" }}
    </span>
  <ng-template #titleLoading>carregando...</ng-template>
</app-header-page>


<div class="container no-margin-bottom">
  <div class="row">
    <div class="col-md-4">
      <mat-tab-group mat-stretch-tabs animationDuration="0ms">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon svgIcon="pencil" matTooltip="Ocorrência"></mat-icon>
          </ng-template>

          <ng-template matTabContent>
            <mat-card>

              <form (keydown.enter)="false" [formGroup]="editForm" #documentEditForm="ngForm"
                    (ngSubmit)="onSubmitForm()" novalidate>
                <mat-card-content class="margin-top">
                  <div class="row">
                    <div class="col">
                      <mat-form-field id="space-divider">
                        <mat-select formControlName="status" placeholder="Status">
                          <mat-option *ngFor="let status of statusOptions"
                                      [value]="status.name">
                            {{status.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <mat-form-field>
                        <mat-select formControlName="priority" placeholder="Prioridade">
                          <mat-option *ngFor="let priority of appCommon.priorityOptions"
                                      [value]="priority.value">
                            {{priority.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <mat-form-field>
                        <input matInput formControlName="completion_date"
                               [min]="minDate"
                               [matDatepicker]="picker"
                               maxlength="8"
                               placeholder="Previsão de encerramento" appMaskDate>
                        <mat-datepicker-toggle matSuffix [for]="picker" disabled></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error>Data de encerramento inválida.</mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <mat-form-field>
                        <textarea class="hidden-arrows" matInput cdkTextareaAutosize formControlName="note"
                                  placeholder="Observação pública."></textarea>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row" *ngIf="!occurrence.closed">
                    <div class="col margin-top">
                      <mat-slide-toggle labelPosition="before" formControlName="closed">
                        Finalizar
                      </mat-slide-toggle>
                    </div>
                  </div>

                  <div class="row" *ngIf="occurrence.closed">
                    <div class="col margin-top">
                      <strong>Finalizado</strong>
                    </div>
                  </div>
                </mat-card-content>
              </form>


              <mat-card-actions *ngIf="canEdit && !loading">

                <p *ngIf="loadingEditForm" style="margin-left: 16px;">Carregando...</p>

                <button *ngIf="editForm.enabled" mat-button (click)="resetForm()">CANCELAR</button>

                <button *ngIf="(editForm.disabled && !loadingEditForm) && !occurrence.closed" mat-button
                        (click)="editForm.enable()">
                  EDITAR
                </button>


                <button *ngIf="editForm.enabled" mat-button [disabled]="!editForm.dirty"
                        (click)="documentEditForm.ngSubmit.emit()">
                  SALVAR
                </button>

              </mat-card-actions>
            </mat-card>
          </ng-template>
        </mat-tab>

        <mat-tab [disabled]="historic === 0" style="height: 99%;">
          <ng-template mat-tab-label>
            <mat-icon svgIcon="history" matTooltip="Histórico"></mat-icon>
          </ng-template>
          <ng-template matTabContent style="overflow-x: hidden">

            <div style="height: calc(100vh - 160px) !important;" class="h-50 h-content-50 h-content-100">
              <app-history-list [historic]="childOccurrences[0].historic"
                                *ngIf="childOccurrences[0].historic.length > 0"></app-history-list>
            </div>

            <div class="row">
              <div class="col text-center">
                <strong *ngIf="childOccurrences[0].historic.length === 0"> Sem histórico</strong>
              </div>
            </div>
          </ng-template>
        </mat-tab>

      </mat-tab-group>
    </div>

    <div class="col-md-8 col-sm-12" style="height: 88vh">
      <mat-tab-group mat-stretch-tabs style="height: 100%;">
        <mat-tab style="overflow:auto !important;">
          <ng-template mat-tab-label>
            Ocorrências
          </ng-template>
          <div class=" h-content-100" style="height: 99%">
            <mat-card style="padding: 0" *ngFor="let itemOfGroup of childOccurrences">


              <a target="_blank" color="primary" class="float-right" style="margin-top: 2px;"
                 [routerLink]="['/occurrences/', itemOfGroup.id ]">
                Editar
                <mat-icon class="align-edit" svgIcon="open-in-new"></mat-icon>
              </a>

              <mat-icon class="float-right mat-small text-subtitle-color" (click)="openUserInfo(itemOfGroup)"
                        matTooltip="Visualizar informações do usuario"
                        style="margin-text-outline: 5px; margin-right: 5px; cursor: pointer;"
                        svgIcon="account">
              </mat-icon>

              <div class="row">

                <mat-card class="col-4 image-box shadow-none" *ngIf="itemOfGroup.url_image">
                  <img mat-card-image
                       src="{{itemOfGroup.url_image}}" alt="Foto da ocorrência"
                       (click)="openImage(itemOfGroup.url_image)">
                </mat-card>

                <div class="col-4 image-box shadow-none" *ngIf="!itemOfGroup.url_image">
                  <img mat-card-image class="no-image"
                       src="../../../../assets/img/picture.svg" alt="Foto da ocorrência">
                </div>

                <mat-divider [vertical]="true" style="margin: 10px 0 10px 0"></mat-divider>

                <div class="col-6 align-self-center" style="max-width: fit-content">

                  <div class="row" *ngIf="itemOfGroup.description">
                    <div class="col-12 mat-small no-margin-bottom">
                      <strong>Descrição:</strong> {{ itemOfGroup.description }}
                    </div>
                  </div>

                  <div class="row" *ngIf="itemOfGroup.address">
                    <div class="col-12 mat-small no-margin-bottom">
                      <strong>Endereço:</strong> {{ itemOfGroup.address }}
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 mat-small text-justify no-margin-bottom" *ngIf="itemOfGroup.office_name">
                      <strong>Secretaria:</strong> {{ itemOfGroup.office_name}}
                    </div>
                  </div>

                  <div class="row" *ngIf="itemOfGroup.protocol">
                    <div class="col-12 mat-small text-justify no-margin-bottom" *ngIf="itemOfGroup.office_name">
                      <strong>Protocolo:</strong> {{ itemOfGroup.protocol}}
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 mat-small text-subtitle-color no-margin-bottom">
                      Data de abertura: {{ itemOfGroup.date_started | date:"dd/MM/yyy HH:mm:ss" }}
                    </div>
                  </div>

                </div>
              </div>


            </mat-card>
          </div>
        </mat-tab>

        <mat-tab label="Mapa">
          <div>

            <agm-map [ngClass]="(isLandscape$ | async) ? 'map-web' : 'map-mobile'" [fitBounds]="autofoco"
                     [clickableIcons]="false" [minZoom]="minZoom"
                     [zoom]="zoom" [maxZoom]="maxZoom"
            >
              <agm-marker *ngFor="let occurrence of childOccurrences"
                          [latitude]="occurrence.lat"
                          [longitude]="occurrence.lgt"
                          animation="DROP"
                          [iconUrl]="getIcon(occurrence.icon)"
                          [agmFitBounds]="autofoco"
                          [visible]="occurrence.id===listOccurrencesMouseOver || listOccurrencesMouseOver === null"
                          [markerClickable]="false"
              >
              </agm-marker>
            </agm-map>


          </div>
        </mat-tab>

      </mat-tab-group>

    </div>
  </div>

  <!--      <div class="col item-col-data">-->

  <!--        <div class="row">-->
  <!--          <div class="col-12 mat-h4 no-margin-bottom"><strong>{{item.type_name }}</strong>-->
  <!--          </div>-->
  <!--        </div>-->

  <!--        <div class="row">-->
  <!--          <div class="col-12 mat-small text-justify no-margin-bottom" *ngIf="item.office_name">-->
  <!--            Secretaria: <strong>{{ item.office_name}}</strong>-->
  <!--          </div>-->
  <!--        </div>-->

  <!--        <div class="row" *ngIf="!item.is_ombudsman">-->
  <!--          <div class="col-12 mat-h4 text-justify no-margin-bottom">-->
  <!--            Itens agrupados: <strong> {{ item.occurrences_number }} </strong>-->
  <!--          </div>-->
  <!--        </div>-->

  <!--      </div>-->

</div>
