import {Component, Inject, OnInit} from '@angular/core';
import {fromPromise} from 'rxjs/internal-compatibility';
import {graphqlOperation} from 'aws-amplify';
import {getOccurrence, listContactsOfUserMobile, listOccurrencesByOfficePagination} from '../../../../graphql/queries';
import {FormOccurrenceComponent} from '../../../pages/occurrences/form-occurrence/form-occurrence.component';
import {AmplifyService} from 'aws-amplify-angular';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from '../../../core/auth/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {CacheService} from '../../../pages/cache/cache.service';
import {AppCommon} from '../../model/common';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {Occurrence} from '../../model/occurrence';
import {UserContacts} from '../../model/userContacts';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {
  loading: any;
  occurrence: Occurrence;
  userContacts: Array<UserContacts>;

  constructor(
    private amplifyService: AmplifyService,
    private activateRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private cacheService: CacheService,
    private appCommon: AppCommon,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: Occurrence,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    // this.loadUser();
    this.listUserContacts();
  }


  // loadUser() {
  //   this.activateRoute.params.subscribe(params => {
  //     const id = params.id;
  //     this.occurrence.id = id;
  //
  //     if (id !== undefined && id !== null) {
  //       fromPromise(this.amplifyService.api().graphql(graphqlOperation(getOccurrence, {id})))
  //         .subscribe(
  //           resp => {
  //             const result: any = resp; // so para corrigir bug 'Property 'data' does not exist on type '{}''
  //             this.occurrence = result.data.getOccurrence;
  //             this.occurrence.priority = this.appCommon.priorityEnum(this.occurrence.priority);
  //             this.loading = false;
  //             console.log(this.occurrence);
  //           },
  //           error => console.log(error)
  //         );
  //
  //     } else {
  //       this.loading = false;
  //     }
  //   });
  // }

  listUserContacts() {

    fromPromise(this.amplifyService.api().graphql(
      graphqlOperation(listContactsOfUserMobile, {id: this.data.created_by})))
      .subscribe(
        resp => {
          const result: any = resp;
          this.userContacts = result.data.listContactsOfUserMobile;
        },
        error => console.log(error)
      );
  }
}
