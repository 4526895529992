import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';


import {HeaderPageComponent} from './header-page/header-page.component';
import {SubHeaderPageComponent} from './sub-header-page/sub-header-page.component';
import {ActionButtonsComponent} from './action-buttons/action-buttons.component';
import {HeaderResultCardComponent} from './header-result-card/header-result-card.component';

@NgModule({
  declarations: [
    HeaderPageComponent,
    SubHeaderPageComponent,
    HeaderResultCardComponent,
    ActionButtonsComponent,
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    RouterModule
  ],
  exports: [
    HeaderPageComponent,
    SubHeaderPageComponent,
    HeaderResultCardComponent,
    ActionButtonsComponent,
  ]
})
export class HeaderPageModule {
}
